import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Container } from "@components/ui";
import { MainLayout } from "@layouts/MainLayout/MainLayout";
import { SliceZone } from "@prismicio/react";
import { Content } from "@prismicio/client";
import { usePromotionFooter } from "@middleware/hooks/usePromotionFooter";
import { createClient } from "../../../prismicio";
import { components } from "../../../slices";

type PageProps = {
  page: Content.HomepageDocument;
  segment: Content.PageAccueilDocument;
};

export const getStaticProps: GetStaticProps = async ({
  locale,
  previewData,
}) => {
  const client = createClient({ previewData });
  const page = await client.getSingle("homepage");
  const segment = await client.getSingle("page_accueil");

  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        "common",
        "header",
        "footer",
      ])),
      page,
      segment,
    },
  };
};

const HomePage = ({ page, segment }: PageProps) => {
  const { promotionFooterSlices } = usePromotionFooter(segment);

  return (
    <Container className="page-cms">
      <SliceZone slices={page.data.slices} components={components} />
      <SliceZone slices={promotionFooterSlices} components={components} />
    </Container>
  );
};

HomePage.layout = MainLayout;
export default HomePage;
