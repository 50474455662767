import { useEffect, useState } from "react";
import { EGroupCustomer, ESegmentCustomer } from "@middleware/types";
import { CODE_PROMO } from "@middleware/constants";
import { setToStorage } from "@middleware/helpers/global/sessions";
import {
  BarPromotionSlice,
  PageAccueilDocument,
} from "../../../types.generated";
import { useCustomerSegment } from "./useCustomerSegment";
import { useCustomerGroup } from "./useCustomerGroup";

export const usePromotionFooter = (homePagePrismic: PageAccueilDocument) => {
  const defaultSegment = homePagePrismic.data.slices3.filter(
    (slice) => slice.primary.segment === ESegmentCustomer.PROSPECT_0
  );
  const { customerSegment } = useCustomerSegment();
  const { customerGroup } = useCustomerGroup();
  const [promotionFooterSlices, setPromotionFooterSlices] =
    useState<BarPromotionSlice[]>(defaultSegment);

  useEffect(() => {
    const getCorrectSlices = () => {
      if (
        customerGroup === EGroupCustomer.NONE ||
        customerSegment === ESegmentCustomer.NONE
      ) {
        return [];
      }
      const groupSlices = homePagePrismic.data.slices4.filter(
        (slice) => slice.primary.group_code === customerGroup
      );
      if (groupSlices.length > 0) {
        return groupSlices;
      }

      const segmentSlices = homePagePrismic.data.slices3.filter(
        (slice) => slice.primary.segment === customerSegment
      );

      if (
        segmentSlices.length > 0 &&
        customerSegment !== ESegmentCustomer.PROSPECT_0 &&
        segmentSlices[0].primary.segment !== defaultSegment[0].primary.segment
      ) {
        const footerCode = segmentSlices[0]?.primary.promotionCoupon;
        if (footerCode !== "" && typeof footerCode === "string") {
          setToStorage(CODE_PROMO, segmentSlices[0].primary.promotionCoupon);
        }
      }

      return segmentSlices;
    };

    setPromotionFooterSlices(getCorrectSlices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerGroup, customerSegment]);

  return {
    promotionFooterSlices,
  };
};
