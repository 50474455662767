import { useTranslation } from "next-i18next";
import { Header, LoadingPage, ModalUI } from "@components/commun";
import { ErrorBoundary } from "@components/commun/ErrorBoundary/ErrorBoundary";
import { Footer } from "@components/commun/Footer/Footer";
import { Notification } from "@components/commun/Notification/Notification";
import {
  useCart,
  useGlobalMessaging,
  useRedirectIfSubscribed,
  useScript,
} from "@middleware/hooks";

import { IMessage, LayoutProps } from "@middleware/types";
import { useEffect } from "react";
import { usePushCustomerDataLayer } from "@middleware/hooks";
import { SliceZone, useSinglePrismicDocument } from "@prismicio/react";
import { Content } from "@prismicio/client";
import { getCorrectGroupSliceForHeader } from "@middleware/helpers/global/prismic";
import { useCustomerGroup } from "@middleware/hooks/useCustomerGroup";
import { components } from "../../../slices";

export const MainLayout: LayoutProps = ({ children }) => {
  const { t } = useTranslation();
  const { setMessage, cleanMessage, message } = useGlobalMessaging();
  const { loadCart } = useCart();
  const [page] = useSinglePrismicDocument("header_groupe");
  const { customerGroup } = useCustomerGroup();
  usePushCustomerDataLayer();
  useRedirectIfSubscribed();
  useScript("/static/js/cookies.js");

  useEffect(() => {
    const getCart = async () => await loadCart();
    void getCart();

    // avoid fetching cart multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {page !== undefined && (
        <SliceZone
          slices={getCorrectGroupSliceForHeader(
            page as Content.HeaderGroupeDocument,
            customerGroup
          )}
          components={components}
        />
      )}
      <Header />
      <LoadingPage />
      <Notification message={message} clear={cleanMessage} />
      <ErrorBoundary
        translation={t}
        setErreur={(_message: IMessage) => setMessage(_message)}
      >
        <main className="super-main">{children}</main>
      </ErrorBoundary>
      <Footer />
      <ModalUI />
    </>
  );
};
