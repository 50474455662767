import { ESegmentCustomer } from "@middleware/types";
import { useEffect, useState } from "react";
import { useCustomer } from "./useCustomer";
import { useAuth } from "./useAuth";

export const useCustomerSegment = () => {
  const { customer } = useCustomer();
  const { isAuthenticated } = useAuth();
  const [customerSegment, setCustomerSegment] = useState<ESegmentCustomer>(
    ESegmentCustomer.NONE
  );
  useEffect(() => {
    if (isAuthenticated === false) {
      setCustomerSegment(ESegmentCustomer.PROSPECT_0);

      return;
    }

    if (customer === undefined || isAuthenticated === undefined) {
      setCustomerSegment(ESegmentCustomer.NONE);

      return;
    }

    setCustomerSegment(customer.user.segment);
  }, [customer, isAuthenticated]);

  return {
    customerSegment,
  };
};
